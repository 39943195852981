//Реакт
import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiUser from "../../../../../app/api/authorized/admin/user/ApiUser";
import ApiOrderCertificates from "../../../../../app/api/authorized/admin/order/ApiOrderCerificates";
import ApiGenerateCertificate from "../../../../../app/api/authorized/admin/order/ApiGenerateCertificate";
import ApiActivCertificates from "../../../../../app/api/authorized/admin/order/ApiActiveCertificates";
import ApiOrderToEmail from "../../../../../app/api/authorized/admin/order/ApiOrderToEmail";
import ApiBalance from "../../../../../app/api/authorized/admin/booking/ApiBalance";
import ApiBooking from "../../../../../app/api/authorized/admin/booking/ApiBooking";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
import { ContextApp } from "../../../../modules/app/appContext";
import DateLib from "../../../../../inc/DateLib";
//Компоненты
import InputPassword from "../../../../components/inputs/password/password";
import InputText from "../../../../components/inputs/text";
import InputPhone from "../../../../components/inputs/phone/phone";
import InputWrapper from "../../../../components/input/__wrapper";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
import CheckboxBlock from "../../../../components/checkbox-block";
import ButtonLinkAdd from "../../../../components/button-link/__add/button-link__add";
import MultiSelect from "../../../../components/inputs/milti-select";
import ButtonLinkShow from "../../../../components/button-link/__show/button-link__show";
import MyModal from "../../../../components/modals/modal/modal";
import Button from "../../../../components/button/button";
import InputNumber from "../../../../components/inputs/number/number";
import List from "../../../../modules/list";
import ListBodyItem from "../../../../modules/list/__body-item/list__body-item";
import ListHeaderItem from "../../../../modules/list/__header-item/list__header-item";
//MUI Icons
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
//Стили
import './styles.css';
import '../../../styles.css';
import Select from "../../../../components/inputs/select";
import ApiBookingTime from "../../../../../app/api/authorized/admin/booking/ApiBookingTime";
import InputDate from "../../../../components/inputs/date/date";
import { route } from "../../../../../inc/Routes";

export default function UserShow({ ...other }) {
    const [searchParams, setSearchParams] = useState({
        bookings: {},
        certificates: {},
        time: {},
    });

    const handleChangeBookingsSearchParams = (params) => {
        setSearchParams((prev) => ({ ...prev, bookings: params }));
    }

    const handleChangeCertificatesSearchParams = (params) => {
        setSearchParams((prev) => ({ ...prev, certificates: params }));
    }

    const handleChangeTimeSearchParams = (params) => {
        setSearchParams((prev) => ({ ...prev, time: params }));
    }

    const tabs = {
        'User ': 'Профиль',
        'Bookings': 'Бронирования',
        'Certificates': 'Сертификаты',
        'Balances': 'Депозиты',
    }
    
    const [activeComponent, setActiveComponent] = useState(Object.keys(tabs)[0]);

    // Функция для переключения компонентов
    const renderComponent = () => {
        switch (activeComponent) {
            case 'User ':
                return <UserShowContent {...other}/>;
            case 'Bookings':
                return <BookingIndexContent 
                    setSearchParams={handleChangeBookingsSearchParams} 
                    searchParams={searchParams.bookings} 
                    {...other}/>;
            case 'Certificates':
                return <OrderCertificateIndexContent 
                    setSearchParams={handleChangeCertificatesSearchParams} 
                    searchParams={searchParams.certificates} 
                    {...other}/>;
            case 'Balances':
                return <BalanceIndexContent 
                    setSearchParams={handleChangeTimeSearchParams} 
                    searchParams={searchParams.time}  
                    {...other}/>;
            default:
                return <UserShowContent {...other}/>;
        }
    };

    const handleSelectTab = (tab) => {
        setActiveComponent(tab);
    }

    return <Page
        tabs={tabs}
        onSelectTab={handleSelectTab}
        {...other}
        ApiModel={ApiUser}
    >
        {renderComponent()}
    </Page>
}


function UserShowContent({ ...other }) {
    const contextPage = useContext(ContextPage);
    const contextContent = useContext(ContextContent);

    const headerRight = document.querySelector('.content__header-rigth');
    if (headerRight) {
        headerRight.style.visibility = 'visible';
    }

    const [_id, set_id] = useState(null);
    const [_name, set_name] = useState('');
    const [_middlename, set_middlename] = useState('');
    const [_surname, set_surname] = useState('');
    const [_email, set_email] = useState('');
    const [_phone, set_phone] = useState('');
    const [_password, set_password] = useState('');
    const [_passwordConfirmation, set_passwordConfirmation] = useState('');
    const [_created, set_created] = useState(null);
    const [_updated, set_updated] = useState(null);

    const [_selectedRoles, set_selectedRoles] = useState([]);

    const handleSetSelectedItems = (updatedItems) => {
        set_selectedRoles(updatedItems);
    };

    const roles = useSelector(state => state.adminOption.roles);

    contextContent._dataToServer.current = {
        name: _name,
        middle_name: _middlename,
        surname: _surname,
        email: _email,
        phone: _phone,
        roles: _selectedRoles,
        password: _password,
        password_confirmation: _passwordConfirmation
    };
    useLayoutEffect(() => {
        contextContent._handleSetData.current = (data) => {
            set_id(data.id);
            set_name(data.name);
            set_middlename(data.middle_name);
            set_surname(data.surname);
            set_email(data.email);
            set_phone(data.phone);
            set_selectedRoles(data.roles);
            set_password(data.password);
            set_created(data.created_at);
            set_updated(data.updated_at);
            set_passwordConfirmation(data.password_confirmation);
            contextPage.setTitle(`Пользователь: ${data.full_name}`);
            contextContent.setTitle(`Пользователь ${data.full_name}`);
            contextPage.setName(data.full_name);
        };
        contextContent.doLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <>
        {!contextContent.isCreate ?
        <InputWrapper>
            <InputText className="pages__show-input" value={_id} readOnly={true}>id</InputText>
        </InputWrapper> : null}
        <InputWrapper>
            <InputText className="pages__show-input" value={_name} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_name(e.target.value)}>Имя</InputText>
        </InputWrapper>
        <InputWrapper>
            <InputText className="pages__show-input" value={_surname} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_surname(e.target.value)}>Фамилия</InputText>
        </InputWrapper>
        <InputWrapper>
            <InputText className="pages__show-input" value={_middlename} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_middlename(e.target.value)}>Отчество</InputText>
        </InputWrapper>
        <InputWrapper>
            <InputText className="pages__show-input" value={_email} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_email(e.target.value)}>Почта</InputText>
        </InputWrapper>
        <InputWrapper>
            <InputPhone className="pages__show-input" value={_phone} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_phone(e.target.value)}>Телефон</InputPhone>
        </InputWrapper>
        <InputWrapper>
            <InputPassword className="pages__show-input" value={_password} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_password(e.target.value)}>Пароль</InputPassword>
        </InputWrapper>
        <InputWrapper>
            <InputPassword className="pages__show-input" value={_passwordConfirmation} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_passwordConfirmation(e.target.value)}>Подтверждение пароля</InputPassword>
        </InputWrapper>
        <InputWrapper>
            <CheckboxBlock className="pages__show-input" readOnly={!contextContent.isCreateOrEdit} data={roles} selectedItems={_selectedRoles} setSelectedItems={handleSetSelectedItems}>Роли</CheckboxBlock>
        </InputWrapper>
        {!contextContent.isCreate ?
            <InputWrapper>
                <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
            </InputWrapper>
            : null}
        {!contextContent.isCreate ?
            <InputWrapper>
                <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
            </InputWrapper>
            : null}
    </>
}

function BookingIndexContent({setSearchParams, searchParams, ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);
    
    const headerRight = document.querySelector('.content__header-rigth');
    if (headerRight) {
        headerRight.style.visibility = 'hidden';
    }

    const [_typeId, set_typeId] = useState(searchParams.type ?? '');
    const [_statusId, set_statusId] = useState(searchParams.status ?? '');
    const [_orderId, set_orderId] = useState(searchParams.order ?? '');
    const [_dateStart, set_dateStart] = useState(searchParams.dateStart ?? '');
    const [_dateEnd, set_dateEnd] = useState(searchParams._dateEnd ?? '');

    const handleTypeChange = (event) => {
        set_typeId(event.target.value)
    }

    const handleStatusChange = (event) => {
        set_statusId(event.target.value)
    }

    const types = useSelector(state => state.commonOption.bookingTypes);
    const statuses = useSelector(state => state.commonOption.bookingStatuses);
    const _userId = contextPage.id

    useLayoutEffect(() => {
        const newTitle = 'Бронирования пользователя:'; // Слово, на которое вы хотите заменить
        const currentTitle = contextContent.title;
        const titleParts = currentTitle.split(' ');
        titleParts[0] = newTitle;
        const updatedTitle = titleParts.join(' ');
        contextPage.setTitle(updatedTitle);
    }, [contextContent, contextPage]);

    useEffect(() => {
        setSearchParams({ 
            order: _orderId, 
            type: _typeId, 
            status: _statusId, 
            dateStart: _dateStart, 
            dateEnd: _dateEnd 
        })
    }, [_orderId, _typeId, _statusId, _dateStart, _dateEnd ])

    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="order_id"
            name="order_id"
        >
            Заказ
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_start"
            name="date_start"
        >
            Начало
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_end"
            name="date_end"
        >
            Окончание
        </ListHeaderItem>,
        <ListHeaderItem
            key="minutes"
            name="minutes"
        >
            Минуты
        </ListHeaderItem>,
        <ListHeaderItem
            key="type_id"
            name="type_id"
        >
            Приобритен через
        </ListHeaderItem>,
        <ListHeaderItem
            key="status_id"
            name="status_id"
        >
            Статус бронирования
        </ListHeaderItem>,
        <ListHeaderItem
            key="payment"
            name="payment"
            isSort={false}
        >
            Оплата
        </ListHeaderItem>,
        <ListHeaderItem
            key="payment_type_id"
            name="payment_type_id"
        >
            Тип оплаты
        </ListHeaderItem>,
        <ListHeaderItem
            key="count_people"
            name="count_people"
        >
            Количество человек
        </ListHeaderItem>,
    ];

    const filters = [
        <Select className="input_wf" options={types} value={_typeId} onChange={handleTypeChange}>Приобретен через</Select>,
        <Select className="input_wf" options={statuses} value={_statusId} onChange={handleStatusChange}>Статус</Select>,
        <InputNumber className="input_wf" value={_orderId} onChange={(e) => { set_orderId(e.target.value) }}>Номер заказа</InputNumber>,
        <InputDatetime className="input_wf" value={_dateStart} onChange={(e) => { set_dateStart(e.target.value) }}>Начало после</InputDatetime>,
        <InputDatetime className="input_wf" value={_dateEnd} onChange={(e) => { set_dateEnd(e.target.value) }}>Окончание до</InputDatetime>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-bookings_column-width" key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="order_id" data-label={header[1].props.children}>{ row.order_id }</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="date_start" data-label={header[2].props.children}>{DateLib.formatterDateTime(row.date_start)}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="date_end" data-label={header[3].props.children}>{DateLib.formatterDateTime(row.date_end)}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="minutes" data-label={header[4].props.children}>{row.minutes}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="type_id" data-label={header[5].props.children}>{row.type_name}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="status_id" data-label={header[6].props.children}>{row.status_name}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="payment" data-label={header[7].props.children}>
                {row.order_total ? <div>{row.order_total} ₽</div> : null}
                {row.balance_user_name ? <div>{row.balance_user_name}</div> : null}
                {row.time_type_name ? <div>{row.time_type_name}</div> : null}
                {row.time_minutes ? <div>{row.time_minutes}</div> : null}
            </ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="payment_type_id" data-label={header[8].props.children}>{row.payment_type_name}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="count_people" data-label={header[9].props.children}>{row.count_people}</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiBooking}
            renderRow={renderRow}
            defaultSortKey='id'
            searchParams={{...searchParams, userId: _userId, }}
        />
    </>
}

function BalanceIndexContent({setSearchParams, searchParams, ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const _userId = contextPage.id
    const [_statusId, set_statusId] = useState(searchParams.status ?? '')
    const [_balanceTypeId, set_balanceTypeId] = useState(searchParams.balanceTypeId ?? '')
    const [_typeId, set_typeId] = useState(searchParams.type ?? '')
    const [_paymentId, set_paymentId] = useState(searchParams.payment ?? '')
    const [_createdAt, set_createdAt] = useState(searchParams.createdAt ?? '')
    const [_updatedAt, set_updatedAt] = useState(searchParams.updatedAt ?? '')

    const paymentTypes = useSelector(state => state.commonOption.paymentTypes);
    const bookingTimeStatuses = useSelector(state => state.adminOption.bookingTimeStatuses);
    const bookingTimeTypes = useSelector(state => state.adminOption.bookingTimeTypes);

    const headerRight = document.querySelector('.content__header-rigth');
    if (headerRight) {
        headerRight.style.visibility = 'hidden';
    }

    const handleSetBalanceTypeId = (id) => {
        set_balanceTypeId(id)
    }

    useEffect(() => {
        setSearchParams({ 
            type: _typeId,
            balanceType: _balanceTypeId,
            payment: _paymentId,
            status: _statusId,
            createdAt: _createdAt,
            updatedAt: _updatedAt
        })
    }, [_typeId, _paymentId, _statusId, _balanceTypeId, _createdAt, _updatedAt])

    useLayoutEffect(() => {
        const newTitle = 'Депозиты пользователя:'; // Слово, на которое вы хотите заменить
        const currentTitle = contextContent.title;
        const titleParts = currentTitle.split(' ');
        titleParts[0] = newTitle;
        const updatedTitle = titleParts.join(' ');
        contextPage.setTitle(updatedTitle);
    }, [contextContent, contextPage]);

    // Депозиты
    const headerBalance = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="balance"
            name="balance"
        >
            Баланс, мин
        </ListHeaderItem>,
        <ListHeaderItem
            key="type"
            name="type"
        >
            Тип
        </ListHeaderItem>,
    ];

    const renderRowBalance = (row, i) => {
        if(!_balanceTypeId && row && row.type_id) {
            handleSetBalanceTypeId(row.type_id)
        }
        return [
            <ListBodyItem className="admin-balances_column-width" key="id" data-label={headerBalance[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-balances_column-width" key="balance" data-label={headerBalance[1].props.children}>{row.balance}</ListBodyItem>,
            <ListBodyItem onClick={() => {set_balanceTypeId(row.type_id)}} className={`admin-balances_column-width ${row.type_id != _balanceTypeId ? 'user__balance' : 'user__balance--active'}`} key="type" data-label={headerBalance[2].props.children}><span>{row.type_name}</span></ListBodyItem>,
        ];
    }

    // Транзакции
    const headerTime = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="minutes"
            name="minutes"
        >
            Минуты
        </ListHeaderItem>,
        <ListHeaderItem
            key="type"
            name="type"
        >
            Тип операции
        </ListHeaderItem>,
        <ListHeaderItem
            key="status"
            name="status"
        >
            Статус операции
        </ListHeaderItem>,
        <ListHeaderItem
            key="paymentType"
            name="paymentType"
        >
            Способ оплаты
        </ListHeaderItem>,
        <ListHeaderItem
            key="created_at"
            name="created_at"
        >
            Создан
        </ListHeaderItem>,
        <ListHeaderItem
            key="updated_at"
            name="updated_at"
        >
            Обновлен
        </ListHeaderItem>,
    ];

    const filters = [
        <Select className="input_wf" options={bookingTimeTypes} value={_typeId} onChange={(e) => {set_typeId(e.target.value)}}>Тип операции</Select>,
        <Select className="input_wf" options={bookingTimeStatuses} value={_statusId} onChange={(e) => {set_statusId(e.target.value)}}>Стату операции</Select>,
        <Select className="input_wf" options={paymentTypes} value={_paymentId} onChange={(e) => {set_paymentId(e.target.value)}}>Способ оплаты</Select>,
        <InputDate className="input_wf" value={_createdAt} onChange={(e) => { set_createdAt(e.target.value) }}>Создана</InputDate>,
        <InputDate className="input_wf" value={_updatedAt} onChange={(e) => { set_updatedAt(e.target.value) }}>Обновлена</InputDate>
    ];

    const renderRowTime = (row, i) => {
        return [
            <ListBodyItem className="admin-times_column-width" key="id" data-label={headerTime[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="minutes" data-label={headerTime[1].props.children}>{row.minutes}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="type"  data-label={headerTime[2].props.children}>{row.type_name}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="status" data-label={headerTime[3].props.children}>{row.status_name}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="paymentType" data-label={headerTime[4].props.children}>{row.payment_type_name}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="created_at" data-label={headerTime[5].props.children}>{DateLib.formatterDate(row.created_at)}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="updated_at" data-label={headerTime[6].props.children}>{DateLib.formatterDate(row.updated_at)}</ListBodyItem>,
        ];
    }

    return <>
        <List
            header={headerBalance}
            filters={[]}
            ApiModel={ApiBalance}
            renderRow={renderRowBalance}
            zoomOn={false}
            searchParams={{ user_id: contextPage.id }}
        />
        <List
            header={headerTime}
            filters={filters}
            ApiModel={ApiBookingTime}
            renderRow={renderRowTime}
            searchParams={{...searchParams, userId: _userId }}
        />
    </>
}

function OrderCertificateIndexContent({setSearchParams, searchParams,  ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);
    const { checkPermission } = useContext(ContextApp);

    const headerRight = document.querySelector('.content__header-rigth');
    if (headerRight) {
        headerRight.style.visibility = 'hidden';
    }

    const _userId = contextPage.id

    const [_code, set_code] = useState(searchParams.code ?? '');
    const [_productIds, set_productIds] = useState(searchParams.products ?? '');
    const [_promocodeName, set_promocodeName] = useState(searchParams.promocode ?? '');
    const [_isActionLoading, set_isActionLoading] = useState(false);
    const [_sendTo, set_sendTo] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [_sendEmailData, set_sendEmailData] = useState([]);
    const [_statusId, set_statusId] = useState(searchParams.status ?? '')

    const products = useSelector(state => state.adminOption.products);
    const statuses = useSelector(state => state.commonOption.paymentStatuses);

    useEffect(() => {
        setSearchParams({ 
            code: _code, 
            products: _productIds, 
            promocode: _promocodeName, 
            status: _statusId 
        })
    }, [_code, _productIds, _promocodeName, _statusId ])

    const handleStatusChange = (event) => {
        set_statusId(event.target.value)
    }

    const setMultiEmailData = (data) => {
        openModal();
        set_sendEmailData(data);
    };

    const sendMultiEmail = () => {
        const fetchEmailSend = async () => {
            // Извлекаем идентификаторы заказов из массива data
            const orders = _sendEmailData.map(item => item.id);
            set_isActionLoading(true);
            // Создаем API вызов с необходимыми параметрами
            let apiEmailSend = ApiOrderToEmail.Store({
                email: _sendTo, // Замените на нужный email
                orders: orders // Передаем массив идентификаторов заказов
            });

            await apiEmailSend.getResponse({});
            set_isActionLoading(false);
            set_sendEmailData([])
            closeModal()
        };

        fetchEmailSend();
    };

    const deactivateMultiCertificate = (data) => {

        const fetchDeactivate = async () => {
            set_isActionLoading(true);
            const orders = data.map(item => item.id);

            let apiActiveSet = ApiActivCertificates.Store({
                active: 0,
                orders: orders
            });

            await apiActiveSet.getResponse({});
            set_isActionLoading(false);
        };

        fetchDeactivate();
    };

    const activateMultiCertificate = (data) => {

        const fetchActivate = async () => {
            set_isActionLoading(true);
            const orders = data.map(item => item.id);

            let apiActiveSet = ApiActivCertificates.Store({
                active: 1,
                orders: orders
            });

            await apiActiveSet.getResponse({});
            set_isActionLoading(false);
        };

        fetchActivate();
    };

    const generateCertificate = (data) => {

        const fetchGenerateCertificate = async () => {
            set_isActionLoading(true);
            const orders = data.map(item => item.id);

            let apiGenerateCode = ApiGenerateCertificate.Store({
                orders: orders
            });

            await apiGenerateCode.getResponse({});
            set_isActionLoading(false);
        };

        fetchGenerateCertificate();

    };

    const handleProductsChange = (event) => {
        set_productIds(event.target.value);
    };

    const openModal = () => {
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
      };

    useLayoutEffect(() => {
        const newTitle = 'Сертификаты пользователя:'; // Слово, на которое вы хотите заменить
        const currentTitle = contextContent.title;
        const titleParts = currentTitle.split(' ');
        titleParts[0] = newTitle;
        const updatedTitle = titleParts.join(' ');
        contextPage.setTitle(updatedTitle);
    }, [contextContent, contextPage]);

    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="code"
            name="code"
        >
            Код сертификата
        </ListHeaderItem>,
        <ListHeaderItem
            key="price"
            name="price"
        >
            Итого, руб
        </ListHeaderItem>,
        <ListHeaderItem
            key="product"
            name="product"
            isSort={false}
        >
            Продукт
        </ListHeaderItem>,
        <ListHeaderItem
            key="promocode"
            name="promocode"
        >
            Промокод
        </ListHeaderItem>,
        <ListHeaderItem
            key="status"
            name="status"
        >
            Статус
        </ListHeaderItem>,
        <ListHeaderItem
            key="is_active"
            name="is_active"
            isSort={false}
        >
            Актив.
        </ListHeaderItem>,
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
            className="create_mobile"
        >
            {checkPermission("admin.order.certificates.create") ? <ButtonLinkAdd href={"authorized.account.order.certificates.create"} /> : null}
        </ListHeaderItem>,
    ];

    const filters = [
        <InputText className="input_wf" value={_code} onChange={(e) => { set_code(e.target.value) }}>Код</InputText>,
        <MultiSelect className="input_wf" options={products} value={_productIds} onChange={handleProductsChange}>Продукты</MultiSelect>,
        <InputText className="input_wf" value={_promocodeName} onChange={(e) => { set_promocodeName(e.target.value) }}>Промокод</InputText>,
        <Select className="input_wf" options={statuses} value={_statusId} onChange={handleStatusChange}>Статус</Select>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-certificates_column-width" key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="code" data-label={header[1].props.children}><a target="_blank" href={row.file}>{row.code}</a></ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="price" data-label={header[2].props.children}>{row.total} ₽</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="product" data-label={header[3].props.children}>
                {row.products.map((product) => (
                    <div key={product.id}>
                        <div>Время: {product.minutes}</div>
                        <div>Цена: {product.price} ₽ ({product.total} ₽)</div>
                    </div>
                ))}
            </ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="promocode" data-label={header[4].props.children}>{row.promocode_name}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="status" data-label={header[5].props.children}>{row.status}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="is_active" data-label={header[6].props.children}>
                {
                    row.is_active ? <div className="is-active_icon--success"><VerifiedIcon style={{fill: 'rgb(80 157 207)' }}/></div>
                        : <div className="is-active_icon--danger"><NewReleasesIcon /></div>
                }
            </ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="show" >
                {checkPermission('admin.order.certificates.show') ? <ButtonLinkShow target="_blank" href={route("authorized.admin.order.certificates.show", { id: row.id })} /> : null}
            </ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiOrderCertificates}
            renderRow={renderRow}
            useRowSelect={true}
            isActiomLoading={_isActionLoading}
            searchParams={{...searchParams, userId: _userId }}
            functions={
                {
                    'Отправить на Email': setMultiEmailData,
                    'Деактивировать': deactivateMultiCertificate,
                    'Активировать': activateMultiCertificate,
                    'Сгенерировать сертификаты': generateCertificate,
                }
            }
        />
        <MyModal className="certificate__email" isOpenModal={isModalOpen} closeModal={closeModal} title={"email"}>
            <div className="certificate__email_content">
                <InputText className="input_wf" value={_sendTo} onChange={(e) => { set_sendTo(e.target.value) }}>E-mail</InputText>,
                <Button onClick={sendMultiEmail}>Отправить</Button>
            </div>
        </MyModal>
    </>
}